<template>
  <div class="news-details">
    <van-skeleton title :row="50" :loading="loading">
      <div class="news-details-content">
        <div class="news-details-title">
          {{ details.title }}
        </div>
        <div class="news-details-time">
          {{ details.send_time || details.create_time }}
        </div>
        <!-- <div class="ql-editor"  v-html="details.content"></div> -->
        <quillEditor v-model="details.content" />
      </div>
    </van-skeleton>
  </div>
</template>

<script>
import { fetchNewsDetails } from "@/api/news";
import quillEditor from '@/components/QuillEditor.vue'

export default {
  components:{
    quillEditor
  },
  data() {
    return {
      details: {},
      relatList: [],
      loading: true,
      images: []
    };
  },
  watch: {
    $route() {
      this.getDetails();
      this.getRelat();
    }
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    pid() {
      return this.$route.params.pid;
    }
  },
  methods: {
    async getDetails() {
      const { result } = await fetchNewsDetails(this.id);
      this.details = result;
      this.loading = false;
      this.$nextTick(() => {
        var images = document.getElementsByTagName('img')
        for (let i of images) {
          var img = i.src
          if (screen.height + 20 < i.offsetTop) {
            i.src = ''
          }
          i.dataset.src = img
        }
        this.images = images
        document.onscroll = () => {
          for (let item of this.images) {
            if (document.documentElement.scrollTop >= item.offsetTop - screen.height - 20) {
              item.src = item.dataset.src
            }
          }
        }
      })
    },
    // async getRelat() {
    //   const { result } = await fetchBrandProject({
    //     cate_id: this.pid,
    //     pageSize: 4,
    //     pageNo: 1
    //   });
    //   const list = result.data.filter(item => item.id != this.id);
    //   this.relatList = list.slice(0, 3);
    // }
  },
  mounted() {
    this.getDetails();

  },
};
</script>

<style lang="less" scoped>
.ql-editor {
  section {
    box-sizing: border-box;
  }
}

.news-details {
  .news-details-content {
    padding: 3vw;
    font-family: PingFang-SC-Bold;
    font-size: 5vw;
    color: #404040;

    .news-details-time {
      margin-bottom: 5vw;
      margin-top: 5vw;
      font-family: PingFang-SC-Medium;
      font-size: 3vw;
      color: #999999;
    }



    .relat-list {
      margin-top: 7vw;

      .relat-title {
        font-family: PingFang-SC-Bold;
        font-size: 5vw;
        color: #404040;
      }
    }
  }
}

.relat-item {
  margin-top: 4vw;
}

.relat-item-cover {
  width: 31vw;
  height: 21vw;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
  }
}

.relat-right-title-time {
  margin-left: 2vw;

  .relat-right-title {
    font-family: PingFang-SC-Bold;
    font-size: 3vw;
    color: #404040;
  }

  .relat-right-time {
    margin-top: 3vw;
    font-family: PingFang-SC-Regular;
    font-size: 3vw;
    color: #8c8c8c;
  }
}
</style>

<style lang="less">
.ql-container {
      border-width:0 !important; 
  
}
.quill-box {
  height: auto !important;
    border-width:0 !important; 
}
.ql-editor {
    border-width:0 !important; 

  padding:0
}
.news-details-html {
  section {
    max-width: 100%;
  }

  img {
    max-width: 100%;
  }

  img {
    // display: none;
  }
}
</style>
